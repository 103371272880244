import React, { useRef, useEffect, useState } from 'react'
import { MapAreaData } from '../styling/StyledComponents'
import mapboxgl from 'mapbox-gl';
import Cookies from 'js-cookie';
import { addBuildings } from '../MapLayers/BuildingLayer';
import { LocationData } from '../Geolocation/LocationProvider';
import axios from 'axios'
import { addSourceToLinesLayer, createLines, createLinesToSource } from '../MapLayers/LinesLayer';

function UsersTimeMap() {
    mapboxgl.accessToken = Cookies.get('mapbox_token')
    const [location, setLocation] = LocationData()
    const [zoom, setZoom] = useState(12.3);
    const [data, setData] = useState([])

    const mapContainer = useRef(null);
    const map = useRef(null);
    const userLines = useRef(null);

    const getAllData = async () => {
        const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/answers/timeframed`, {},
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
            },
            withCredentials: true
        })
        .then(res => res.data)

        console.log(data.result)
        if (data.success) {
            setData(data.result)
        }
    }

  useEffect(() => {
    if (mapboxgl.accessToken == undefined || map.current) return; // initialize map only once

    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_CUSTOM_MAP,
        center: [location.lng, location.lat],
        zoom: zoom,
        pitch: 30,
        dragPan: true,
        doubleClickZoom: true,
        scrollZoom: true,
        dragRotate: true,
      });
    } catch (err) {
      console.log(err)
      return
    }

    map.current.on('move', () => {
      setLocation({
        lng: map.current.getCenter().lng.toFixed(4), 
        lat: map.current.getCenter().lat.toFixed(4)
      })
      setZoom(map.current.getZoom().toFixed(2));
    });

    map.current.on('click', 'userlines', (e) => {
      const clickedPoint = e.lngLat;

      const coordinates = e.features[0].geometry.coordinates.slice();
      const properties = e.features[0].properties;
      console.log(coordinates)

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0][0]) > 180) {
          coordinates[0][0] += e.lngLat.lng > coordinates[0][0] ? 360 : -360;
      }

      new mapboxgl.Popup()
          .setLngLat([clickedPoint.lng, clickedPoint.lat])
          .setHTML(
            `
            <div>
              <p><h3>${properties.name}</h3></p>
              <p>Age: ${properties.age}</p>
              <p>Gender: ${properties.gender}</p>
            </div>
            `
          )
          .addTo(map.current);
    });

    map.current.on('load', () => {
      try {
        createLinesToSource('userlines', map.current, data)
        addSourceToLinesLayer('userlines', map.current)

        addBuildings(map.current)

        userLines.current = map.current.getSource('userlines')
      } catch (err) {
        console.log(err)
        return
      }
    });

    getAllData()
  }, []);

  useEffect(() => {

    try {
      if (userLines.current != null) {
        userLines.current.setData(createLines(data))
        console.log(data)
      }
    } catch (err) {
      console.log(err)
    }
  }, [data, userLines.current])

  return (
      <MapAreaData ref={mapContainer} />
  );
}

export default UsersTimeMap
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Label, RoundButton } from '../styling/StyledComponents';
import Cookies from 'js-cookie';
import { QuestionData } from '../Geolocation/QuestionProvider';
import { LocationData } from '../Geolocation/LocationProvider';

function CircleSelector() {
  const [questionInfo, setQuestionInfo] = QuestionData()
  const [location] = LocationData()
  const [show, setShow] = useState(false)
  const [rating, setRating] = useState(-1)

  try {
    var nums = Array.from(new Array(questionInfo.max_rating - questionInfo.min_rating + 1).keys())
    var ratings = nums.map((elem) => elem + questionInfo.min_rating);
  } catch (e) {
    console.log(e)
  }
  const handleOnClick = async (number) => {
    setRating(number)
    const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/answer`, {rating: number, coords: location, question_id: questionInfo.id},
      {
          headers: {
              Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
          },
          withCredentials: true
      }
    )
    .then(res => res.data)

    if (!data.success) {
      alert("Failed to insert data")
    }
  }

  useEffect(() => {
    if (rating != -1) {
      setShow(true)
      setTimeout(() => {setShow(false)}, 1000)  
    }
  }, [rating])

  return (
    <div className='flex-column center'>
      { show && (
        <Label>You answered {rating}</Label>
      )}
      <div className='center padding-m circle-direction'>
        <Label>{questionInfo.min_text}</Label>
        <div className='circle-direction'>
          {ratings.map((item)=>{
            return <RoundButton key={item} onClick={() => handleOnClick(item)}>{item}</RoundButton>;
          })}
        </div>
        <Label>{questionInfo.max_text}</Label>
      </div>
    </div>
  )
}

export default CircleSelector
import React, { useEffect } from 'react'
import { AuthData } from './AuthProvider'
import axios from 'axios'
import Cookies from 'js-cookie';

/**
 * This component which is required when a user refreshes a page that is 
 * publicly available to then know their authentication status.
 *
 * @param {Component} children The wrapped react components
 * @returns {Component} Wrapped components
 */
const PublicRoute = ({children}) => {
    const [user, setUser] = AuthData()

    const fetchAuth = async () => {
        let data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hasAuth`, {},
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
                },
                withCredentials: true
            }
        ).then(res => res.data);
        
        if (data.auth) {
            let userData = data.result
            if (data.isAdmin) {
                userData.isAdmin = data.isAdmin
            }
            userData.auth = data.auth
            setUser(userData)
        } else {
            setUser({})
        }
    }

    useEffect(() => {
        if (user.auth == null) {
            fetchAuth()
        }
    }, [user.auth])

    return children
}

export default PublicRoute
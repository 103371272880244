import React, { useRef, useEffect, useState } from 'react';
import { MapArea } from '../styling/StyledComponents'
import mapboxgl from 'mapbox-gl';
import Cookies from 'js-cookie';
import { addSourceToCircleLayer, createCircleData, createCircleToSource } from '../MapLayers/CircleLayer';
import { addBuildings } from '../MapLayers/BuildingLayer';
import { LocationData } from '../Geolocation/LocationProvider';
import { addSourceToDotLayer } from '../MapLayers/DotLayer';
import { AuthData } from '../auth/AuthProvider';

function HomeMap() {
    mapboxgl.accessToken = Cookies.get('mapbox_token')
    const [user] = AuthData()
    const [location] = LocationData()
    const [zoom] = useState(15.9);

    const mapContainer = useRef(null);
    const map = useRef(null);
    const userArea = useRef(null);
    const userDot = useRef(null);

  useEffect(() => {
    if (mapboxgl.accessToken == undefined || map.current != null || user.auth != true) return; // initialize map only once

    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_GENERAL_MAP,
        center: [location.lng, location.lat],
        zoom: zoom,
        pitch: 30,
        dragPan: false,
        doubleClickZoom: false,
        minzoom: 15,
        maxzoom: 16,
        scrollZoom: false,
        dragRotate: false,
      });
    } catch (err) {
      console.log(err)
      return
    }

    map.current.on('load', () => {
      try {
        createCircleToSource('polygon', map.current, location.lat, location.lng, 0.06, 64);
        createCircleToSource('dot', map.current, location.lat, location.lng, 0.003, 32);

        addSourceToCircleLayer('polygon', map.current);
        addSourceToDotLayer('dot', map.current)
        addBuildings(map.current)

        userArea.current = map.current.getSource('polygon')
        userDot.current = map.current.getSource('dot')
      } catch (err) {
        console.log(err)
        return
      }
    });
  }, [user.auth]);

  useEffect(() => {
    if (map.current != null) {
      map.current.jumpTo({ 'center': [location.lng, location.lat], 'zoom': zoom });

      if (userArea.current != null) {
        userArea.current.setData(createCircleData(location.lat, location.lng, 0.06, 64));
      }

      if (userDot.current != null) {
        userDot.current.setData(createCircleData(location.lat, location.lng, 0.003, 32))
      }
    }
  }, [location.lat, location.lng, userArea.current, userDot.current])

  if (mapContainer != null) {
    return (
      <MapArea type={mapboxgl.accessToken == 'undefined' ? 'placeholder' : ''} ref={mapContainer} className="map-container" >
      </MapArea>
    )
  } else {
    return (
      <MapArea type='placeholder' className="map-container" >
      </MapArea>
    )
  }
}

export default HomeMap
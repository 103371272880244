function createHeatmapDataToSource(source, map, data) {
    let geojson = {
        type: 'geojson',
        data: createHeatmapData(data)
    };

    map.addSource(source, geojson)
}

function createHeatmapData(data) {
    let json_data = {
        'type': 'FeatureCollection',
        'features': []
    }

    for (var elem of data) {
        json_data.features.push({
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [elem.lng, elem.lat]
            },
            "properties": {
                "name": elem.name,
                "age": elem.age,
                "gender": elem.gender,
                "rating": elem.user_rating,
                "date": elem.date
            }
        })
    }

    return json_data
}

function addSourceToHeatmapLayer(source, map) {
    map.addLayer(
        {
          id: source,
          type: 'heatmap',
          source: source,
          paint: {
            "heatmap-color": [
                "interpolate",
                ["linear"],
                ["heatmap-density"],
                0,
                "rgba(0, 0, 255, 0)",
                0.1,
                "royalblue",
                0.3,
                "cyan",
                0.5,
                "lime",
                0.7,
                "yellow",
                1,
                "red"
            ],
            "heatmap-radius": 20
          }
        }, 'waterway-label'
      );
}

export {createHeatmapDataToSource, addSourceToHeatmapLayer, createHeatmapData}
import React from 'react'
import { staticColor } from './ColorsUtils'

// Generate colors for each rating with staticColor.
const getValues = (diff_rating, min_rating) => {
    let data = []
    for (let i = diff_rating; i >= 0; i--) {
        data.push({color: staticColor(i/diff_rating), rating: i+parseInt(min_rating)})
    }
    return data
}

/**
 * This component displays a column of colors generated dynamically and their associated ratings.
 *
 * @param {Int} diff_rating The difference between max and minimum ratings
 * @param {Int} min_rating The minimum rating
 * @returns {ReactElement} A component displaying color indicators with ratings.
 */
function StaticColorIndicator({diff_rating, min_rating}) {

    const ratingColors = getValues(diff_rating, min_rating)
    return (
        <div className='overlay padding-m'>
            {ratingColors.map((item, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <div style={{ width: '20px', height: '20px', backgroundColor: item.color, marginRight: '10px' }}></div>
                    <div style={{ color: 'red'}}>{item.rating}</div>
                </div>
            ))}
        </div>
    );
}

export default StaticColorIndicator
import React, { useState } from 'react';
import axios from 'axios';
import { Label, Select, Button, InputField, QuestionField } from '../styling/StyledComponents';
import Navbar from '../Components/Navigation/Navbar';
import { CoordsData } from '../Geolocation/CoordsProvider';
import CoordsMap from '../MapComponents/CoordsMap';
import Footer from '../Components/Footer/Footer';
import StaticColorIndicator from '../Components/Color/StaticColorIndicator';
import Cookies from 'js-cookie';

function CreateQuestions() {
    const [coords, setCoords] = CoordsData()
    const [questionData, setQuestionData] = useState({
        question: '',
        maxRating: 5,
        minRating: 1,
        maxText: 'Excellent',
        minText: 'Poor',
        radius: 2,
        isPublic: false
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setQuestionData({
            ...questionData,
            [name]: value
        });
    };

    const handleSubmit = async () => {
        try {
            questionData.coords = coords
            const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/question`, questionData,
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
                },
                withCredentials: true
            }).then(res => res.data);
            console.log(data);
        } catch (error) {
            console.error('Error inserting question:', error);
        }
    };

    return (
        <div className='fill-height flex-column between'>
            <Navbar></Navbar>
            <Label className='padding-m center'>Create a New Question</Label>

            <div className='flex-column create-view fill-width-edit'>
                <div className='m-margin'>
                    <div className='flex-row right'>
                        <StaticColorIndicator diff_rating={questionData.maxRating-questionData.minRating} min_rating={questionData.minRating}></StaticColorIndicator>
                    </div>
                    {
                        !questionData.isPublic && (
                            <CoordsMap radius={questionData.radius}></CoordsMap>
                        )
                    }
                </div>

                <div className='flex-column'>
                    <div className='flex-column m-margin'>
                        <Label>Question</Label >
                        
                        <QuestionField
                            type="text" 
                            name="question"
                            value={questionData.question} 
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='center flex-edit-field'>
                        <div className='flex-column m-margin'>
                            <Label>Max Rating</Label>
                            <InputField
                                type="number" 
                                name="maxRating" 
                                value={questionData.maxRating} 
                                onChange={handleInputChange} 
                            />
                            <Label>Min Rating</Label>
                            <InputField 
                                type="number" 
                                name="minRating" 
                                value={questionData.minRating} 
                                onChange={handleInputChange} 
                            />
                        </div>

                        <div className='flex-column m-margin'>
                            <Label>Max Text</Label>
                            <InputField 
                                type="text" 
                                name="maxText" 
                                value={questionData.maxText} 
                                onChange={handleInputChange} 
                            />
                            <Label>Min Text</Label>
                            <InputField 
                                type="text" 
                                name="minText" 
                                value={questionData.minText} 
                                onChange={handleInputChange} 
                            />
                        </div>

                        <div className='flex-column m-margin'>
                            {
                                !questionData.isPublic && (
                                    <>
                                        <Label>Radius (in kilometers)</Label>
                                        <InputField
                                            type="number"
                                            name="radius"
                                            value={questionData.radius}
                                            onChange={handleInputChange}
                                        />
                                    </>
                                )
                            }

                            <Label>Is the question global?</Label>
                            <Select
                                name="isPublic" 
                                value={questionData.isPublic} 
                                onChange={e => setQuestionData({...questionData, isPublic: e.target.value == "true" ? true : false})}>
                                <option value={true}>Yes</option>
                                <option value={false}>No</option>
                            </Select>
                        </div>

                    </div>

                    <Button onClick={handleSubmit} type="submit">Insert Question</Button>
                </div>
            </div>

            <Footer></Footer>
        </div>
    );
}

export default CreateQuestions;

import mapboxgl, {Marker} from "mapbox-gl";
import axios from 'axios'
import Cookies from 'js-cookie';
import { staticColor } from "../Components/Color/ColorsUtils";

export const addSourceToUserDataLayer = (source, map) => {
    map.addLayer({
        'id': source,
        'type': 'symbol',
        'source': source,
        'layout': {},
        paint: {}
    });
}

export const createUserDataToSource = (source, map, data) => {
    const geojson = {
        "type": "geojson",
        "data": {
            "type": 'FeatureCollection',
            "features": createUserData(data)
        }
    }

    map.addSource(source, geojson);
}

export const createUserData = (data) => {
    let json_data = {
        "type": 'FeatureCollection',
        "features": []
    }

    for (var elem of data) {
        json_data.features.push({
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [elem.lng, elem.lat]
            },
            "properties": {
                "name": elem.name,
                "age": elem.age,
                "gender": elem.gender,
                "rating": elem.user_rating,
                "date": elem.date
            }
        })
    }
    return json_data
}

export const createMarkers = (data, map) => {
    let max_diff_rating = 0
    let min_rating = Number.MAX_SAFE_INTEGER

    for (var elem of data) {
        const el = document.createElement('div');
        el.classList.add("marker")

        const diff = elem.max_rating-elem.min_rating
        if (diff > max_diff_rating) {
            max_diff_rating = diff
        }

        if (elem.min_rating < min_rating) {
            min_rating = elem.min_rating
        }

        // Calculate value based on rating and min/max rating
        const value = (elem.user_rating - elem.min_rating) / (elem.max_rating - elem.min_rating);
        const color = staticColor(value)

        el.innerHTML = `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2">
            <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0 1 18 0z" fill="${color}"/>
            <circle cx="12" cy="10" r="3" fill="currentColor" fill-opacity="0.9"/>
        </svg>`;

        const marker = new Marker(el).setLngLat([elem.lng, elem.lat]).addTo(map)

        // Create popup content
        const popupContent = document.createElement('div');
        popupContent.id = `${elem.id}`;
        popupContent.setAttribute('data-is-google', elem.is_google); // Use data attribute to store is_google


        const titleDiv = document.createElement('div')
        titleDiv.classList.add('flex-row')
        titleDiv.classList.add('between')

        const nameHeader = document.createElement('h3');
        nameHeader.textContent = elem.name;
        titleDiv.appendChild(nameHeader)
        const ageParagraph = document.createElement('h4');
        ageParagraph.textContent = `${elem.age} Years`;
        titleDiv.appendChild(ageParagraph)
        
        popupContent.appendChild(titleDiv);


        const ratingParagraph = document.createElement('p');
        ratingParagraph.textContent = `Rating: ${elem.user_rating}`;
        popupContent.appendChild(ratingParagraph);

        const genderParagraph = document.createElement('p');
        genderParagraph.textContent = `Gender: ${elem.gender}`;
        popupContent.appendChild(genderParagraph);
        
        const dateParagraph = document.createElement('p');
        const dateSpan = document.createElement('span');
        dateSpan.textContent = elem.date.split('T')[0] + ' ' + elem.date.split('T')[1].split('.')[0];
        dateParagraph.appendChild(dateSpan);
        popupContent.appendChild(dateParagraph);

        const button = document.createElement('button');
        button.classList.add("delete-button")
        button.textContent = 'Remove Marker';
        button.addEventListener('click', async (e) => {
            const parentDiv = e.target.closest('div'); // Get the parent div
            const markerId = parentDiv.id;
            const isGoogle = parentDiv.getAttribute('data-is-google'); // Retrieve the data attribute

            const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/answer/delete`, {id: markerId, is_google: isGoogle},
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
                },
                withCredentials: true
            })
            marker.remove();
        });

        popupContent.appendChild(button);

        const popup = new mapboxgl.Popup()
            .setLngLat({lon: elem.lng, lat: elem.lat})
            .setDOMContent(popupContent)
            .addTo(map);

        marker.setPopup(popup).togglePopup()
    }
    return [max_diff_rating, min_rating]
}
import { createContext, useContext, useState } from 'react'

const CoordsContext = createContext();
export const CoordsData = () => {
    return useContext(CoordsContext)
}

export const CoordsProvider = ({children}) => {
    const [ coords, setCoords ] = useState({lat: 57.78145, lng: 14.15618})

    return ( // We are sending the user object and setUser function to child components
        <CoordsContext.Provider value={[coords, setCoords]}>{children}</CoordsContext.Provider>
    )
}
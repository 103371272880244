import React from 'react'
import './Navbar.css'
import DesktopNavigation from './DesktopNavigation'
import MobileNavigation from './MobileNavigation'

function Navbar() {
    return (
        <div>
            <DesktopNavigation></DesktopNavigation>
            <MobileNavigation></MobileNavigation>
        </div>
    )
}

export default Navbar
export const addSourceToDotLayer = (source, map) => {
    map.addLayer({
        'id': source,
        'type': 'fill',
        'source': source,
        'layout': {},
        'paint': {
            'fill-color': 'black',
            "fill-opacity": 0.3
        }
    });
}
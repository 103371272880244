import { createContext, useContext, useState } from 'react'

const QuestionContext = createContext();
export const QuestionData = () => {
    return useContext(QuestionContext)
}

export const QuestionProvider = ({children}) => {
    const [questionInfo, setQuestionInfo] = useState({id: 0, question: 'No question found!', max_rating: 5, min_rating: 1, max_text: "Max", min_text: "Min"});

    return ( // We are sending the user object and setUser function to child components
        <QuestionContext.Provider value={[questionInfo, setQuestionInfo]}>{children}</QuestionContext.Provider>
    )
}
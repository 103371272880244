import React from 'react'
import UsersDataMap from '../MapComponents/UsersDataMap'
import UsersTimeMap from '../MapComponents/UsersTimeMap'
import LineColorIndicator from './Color/LineColorIndicator'
import HeatMap from '../MapComponents/HeatMap'
import AverageRatingHeatmap from '../MapComponents/AverageRatingHeatmap'

function MapSelector({mapType}) {

    if (mapType == "all_ratings") {
        return <>
            <UsersDataMap></UsersDataMap>
        </>
    } else if (mapType == "density_heatmap") {
        return <>
            <HeatMap></HeatMap>
        </>
    } else if (mapType == "average_rating_heatmap") {
        return <>
            <AverageRatingHeatmap></AverageRatingHeatmap>
        </>
    } else if (mapType == "rating_user_timeframes") {
        return <>
            <div className='flex-row right'>
                <LineColorIndicator className="right"></LineColorIndicator>
            </div>
            <UsersTimeMap></UsersTimeMap>
        </>
    }
}

export default MapSelector
import { createContext, useContext, useState, useEffect } from 'react'
import { updateGeolocation } from '../Geolocation/Geolocation';


const LocationContext = createContext();
export const LocationData = () => {
    return useContext(LocationContext)
}

export const LocationProvider = ({children}) => {
    const [ location, setLocation ] = useState({lat: 57.78145, lng: 14.15618})

    useEffect(() => {
        if (navigator.geolocation) {
          const interval = setInterval(() => {
            updateGeolocation()
            .then(position => {
                let lat = position.coords.latitude
                let lng = position.coords.longitude
                setLocation({lat: lat, lng: lng })
            }).catch(() => {
              console.log("error")
            })
          }, 5000);
    
          return () => {
            clearInterval(interval);
          };
        }
      }, [navigator.geolocation]);

    return ( // We are sending the user object and setUser function to child components
        <LocationContext.Provider value={[location, setLocation]}>{children}</LocationContext.Provider>
    )
}
import React from 'react'
import { useState } from 'react';
import Navbar from '../Components/Navigation/Navbar'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Label, InputField, Button, Select, ErrorLabel } from "../styling/StyledComponents"
import { AuthData } from '../auth/AuthProvider';
import Cookies from 'js-cookie';
import Footer from '../Components/Footer/Footer';
import '../styling/styles.css'

function RegisterPage() {
  const [user, setUser] = AuthData()
  const [errors, setErrors] = useState({})
  const navigate = useNavigate();

  const [values, setValues] = useState({
    name: '',
    email: '',
    age: '',
    gender: 'male',
    password: ''
  })

  const handleSubmit = async (event) => {
    event.preventDefault();
    let data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/signup`, values)
    .then(res => res.data)

    if (data.auth) {
      Cookies.set('token', data.token, { expires: 600, secure: true });
      Cookies.set('mapbox_token', data.mapbox_token, { expires: 600, secure: true });
      
      let result = data.result
      result.auth = data.auth
      setUser(result)
      navigate('/', { replace: true })
    } else {
      setErrors(data.errors)
    }
  }

  return (
    <div className='fill-height flex-column between'>
      <Navbar></Navbar>
      <div className='center'>
        <form onSubmit={handleSubmit} className='flex-column login-form field-gap login-area'>
          <Label>Name</Label>
          <InputField onChange={e => setValues({...values, name: e.target.value}) }></InputField>
          <ErrorLabel>{errors.name}</ErrorLabel>
          <Label>Email</Label>
          <InputField onChange={e => setValues({...values, email: e.target.value}) }></InputField>
          <ErrorLabel>{errors.email}</ErrorLabel>
          <Label>Gender</Label>
          <Select onChange={e => setValues({...values, gender: e.target.value})}>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="other">Other</option>
          </Select>
          <ErrorLabel>{errors.gender}</ErrorLabel>
          <Label>Age</Label>
          <InputField onChange={e => setValues({...values, age : e.target.value}) }></InputField>
          <ErrorLabel>{errors.age}</ErrorLabel>
          <Label>Password</Label>
          <InputField type="password" onChange={e => setValues({...values, password: e.target.value}) }></InputField>
          <Button>Register</Button>

          <Link to="/login">Login</Link>
        </form>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default RegisterPage
import { useState } from "react";
import NavLinks from './NavLinks'
import { Link } from 'react-router-dom'
import {MdOutlineMenu} from 'react-icons/md'
import {MdClose} from 'react-icons/md';

function MobileNavigation() {
  const [click, setClick] = useState(false);

  const Hamburger = <MdOutlineMenu className="HamburgerMenu" size="30px"
    onClick={() => setClick(!click)} />
  const Close = <MdClose className="HamburgerMenu" size="30px"
    onClick={() => setClick(!click)} />

  return (
    <nav className='mobile-navigation'>
        <Link to="/" className='nav-title'>Questionnaire</Link>
        { click ? Close : Hamburger}
        {click && <NavLinks />}
    </nav>
  )
}

export default MobileNavigation
import React from 'react';
import ReactDOM from 'react-dom/client';
import {createBrowserRouter, RouterProvider} from "react-router-dom"

import { AuthProvider } from './auth/AuthProvider'
import LoginPage from './pages/LoginPage';
import HomePage from './pages/HomePage';
import AboutPage from './pages/AboutPage';
import RegisterPage from './pages/RegisterPage';
import ProtectedRoute from './auth/ProtectedRoute';
import AdminPage from './pages/AdminPage';
import AdminRoute from './auth/AdminRoute';
import CreateQuestions from './pages/CreateQuestions';
import { CoordsProvider } from './Geolocation/CoordsProvider';
import EditQuestion from './pages/EditQuestion';
import PublicRoute from './auth/PublicRoutes';

const router = createBrowserRouter([
  {
    path: '/',
    element: <ProtectedRoute><HomePage /></ProtectedRoute>,
  },
  {
    path: '/data',
    element: <AdminRoute><AdminPage /></AdminRoute>
  },
  {
    path: '/login',
    element: <LoginPage />
  },
  {
    path: '/about',
    element: <PublicRoute><AboutPage /></PublicRoute>
  },
  {
    path: '/register',
    element: <RegisterPage />
  },
  {
    path: '/create/question',
    element: <AdminRoute><CoordsProvider><CreateQuestions /></CoordsProvider></AdminRoute>
  },
  {
    path: '/edit/question',
    element: <AdminRoute><CoordsProvider><EditQuestion /></CoordsProvider></AdminRoute>
  }
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <AuthProvider>
      <RouterProvider router={router} /> 
    </AuthProvider>
);
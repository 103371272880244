import React from 'react'
import Navbar from '../Components/Navigation/Navbar'
import { Label } from '../styling/StyledComponents'
import Footer from '../Components/Footer/Footer'

function AboutPage() {
  return (
    <div className='box'>
        <Navbar></Navbar>
        <div className='flex-column center fill-height small-gap'>
          <Label>This website was made for research</Label>
          <Label>Created by Paul Schmidt & Shueib Abdirahman</Label>
          <Label>For more information about Urbanresearch.site, please contact Géza Fischl by email: figeza@ju.se</Label>
        </div>
        <Footer></Footer>
    </div>
  )
}

export default AboutPage
import React from 'react'
import NavLinks from './NavLinks'
import { Link } from 'react-router-dom'

function DesktopNavigation() {
  return (
    <nav className='desktop-navigation'>
        <Link to="/" className='nav-title'>Questionnaire</Link>
        <NavLinks />
    </nav>
  )
}

export default DesktopNavigation
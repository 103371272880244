import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Label, InputField, Button, DeleteButton, Select } from '../styling/StyledComponents';
import Navbar from '../Components/Navigation/Navbar';
import { CoordsData } from '../Geolocation/CoordsProvider';
import CoordsMap from '../MapComponents/CoordsMap';
import SelectQuestion from '../Components/SelectQuestion';
import Footer from '../Components/Footer/Footer';
import StaticColorIndicator from '../Components/Color/StaticColorIndicator';
import Cookies from 'js-cookie';

function EditQuestion() {
    const [coords, setCoords] = CoordsData();
    const [selectedQuestion, setSelectedQuestion] = useState({
        id: -1,
        question: '',
        max_rating: 5,
        min_rating: 1,
        max_text: 'Excellent',
        min_text: 'Poor',
        radius: 2,
        isGeneral: false
    });
    const [questions, setQuestions] = useState([]);
    const getQuestions = async () => {
            try {
                const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/questions`)
                .then(res => res.data);
                if (data.success) {
                    setQuestions(data.result);
                }
            } catch (error) {
                console.error('Failed to fetch questions:', error);
            }
    }
    useEffect(() => {
         getQuestions()
    }, [])

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setSelectedQuestion(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        console.log(selectedQuestion)
        try {
            const data = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/question/${selectedQuestion.id}`, {
                question: selectedQuestion.question,
                max_rating: selectedQuestion.max_rating,
                min_rating: selectedQuestion.min_rating,
                max_text: selectedQuestion.max_text,
                min_text: selectedQuestion.min_text,
                coordinates: coords,
                radius: selectedQuestion.radius,
                isGeneral: selectedQuestion.isGeneral
            },
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
                },
                withCredentials: true
            }).then(res => res.data);

            if (data.success) {
                alert("Successfully updated question.")
                getQuestions()
            } else {
                alert("Failed to update question")
            }
        } catch (error) {
            console.error('Error updating question:', error);
        }
    };

    return (
        <div className='fill-height flex-column between'>
            <Navbar />
            <Label className='padding-m center'>Edit Question</Label>
            <div className='flex-column fill-width-edit'>
                <div className='flex-column edit-view'>
                    <Label>Question</Label>
                    <InputField
                        type="text" 
                        name="question"
                        value={selectedQuestion.question}
                        onChange={handleInputChange}
                    />
                    <div className='flex-edit-field center'>
                        <div className='flex-row'>
                            <div className='flex-column m-margin'>
                                <Label>Max Rating</Label>
                                <InputField
                                    type="number"
                                    name="max_rating"
                                    value={selectedQuestion.max_rating}
                                    onChange={handleInputChange}
                                />
                                <Label>Min Rating</Label>
                                <InputField
                                    type="number"
                                    name="min_rating"
                                    value={selectedQuestion.min_rating}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        
                        <div className='flex-row'>
                            <div className='flex-column m-margin'>
                                <Label>Max Text</Label>
                                <InputField
                                    type="text"
                                    name="max_text"
                                    value={selectedQuestion.max_text}
                                    onChange={handleInputChange}
                                />
                                <Label>Min Text</Label>
                                <InputField
                                    type="text"
                                    name="min_text"
                                    value={selectedQuestion.min_text}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>

                        <div className='flex-row'>
                            <div className='flex-column m-margin'>
                                {!selectedQuestion.isGeneral && (
                                    <>
                                        <Label>Radius</Label>
                                        <InputField
                                            value={selectedQuestion.radius}
                                            onChange={e => {
                                                setSelectedQuestion({...selectedQuestion, radius: e.target.value});
                                            }}
                                        />
                                    </>
                                )}

                                <Label>Is the question global?</Label>
                                <Select
                                    name="isPublic" 
                                    value={selectedQuestion.isGeneral} 
                                    onChange={e => setSelectedQuestion({...selectedQuestion, isPublic: e.target.value == "true" ? true : false})}>
                                    <option value={true}>Yes</option>
                                    <option value={false}>No</option>
                                </Select>
                            </div>
                        </div>
                    </div>

                    {!selectedQuestion.isGeneral && (
                        <>
                            <div className='flex-row right'>
                                <StaticColorIndicator diff_rating={selectedQuestion.max_rating-selectedQuestion.min_rating} min_rating={selectedQuestion.min_rating}></StaticColorIndicator>
                            </div>
                            <CoordsMap radius={selectedQuestion.radius} />
                        </>
                    )}
                    <Button type="submit" onClick={handleSubmit}>Update Question</Button>

                </div>

                <div className='center flex-column padding-m' >
                    <SelectQuestion setSelectedQuestion={setSelectedQuestion} questions={questions} setQuestions={setQuestions}></SelectQuestion>
                </div>
            </div>
            <Footer></Footer>
        </div>
    );
}

export default EditQuestion;

const ratingColors = [
    "#FF0000CC", // Rating 1 - Red with 80% transparency
    "#FF4000CC", // Rating 2 - Orange with 80% transparency
    "#FF8000CC", // Rating 3 - Dark Orange with 80% transparency
    "#FFBF00CC", // Rating 4 - Yellow with 80% transparency
    "#FFFF00CC", // Rating 5 - Yellow with 80% transparency
    "#BFFF00CC", // Rating 6 - Yellow-Green with 80% transparency
    "#80FF00CC", // Rating 7 - Green with 80% transparency
    "#40FF00CC", // Rating 8 - Light Green with 80% transparency
    "#00FF40CC", // Rating 9 - Lime Green (Adjusted) with 80% transparency
    "#00FF80CC"  // Rating 10 - Bright Green (Adjusted) with 80% transparency
];

// Generate colors with hsl dynamically
const dynamicColor = (value) => {
    const hue = value * 240;
    return `hsl(${hue}, 100%, 50%)`; // 100% saturation, 50% lightness
}

// Generate colors with predefined colors
const staticColor = (value) => {
    // Calculate index to choose color from ratingColors array
    const index = Math.round(value * (ratingColors.length - 1));
    return ratingColors[index];
}

module.exports = {
    dynamicColor,
    staticColor
}
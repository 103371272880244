import React from 'react'

// Show three colors and their text strings
function LineColorIndicator() {
    const pathColors = [
        {color: '#FF0000', text: "Start of Path"},
        {color: '#FFFF00', text: "Middle of Path"},
        {color: '#00FF80', text: "End of Path"}
    ];

    return (
        <div className='overlay padding-m'>
            {pathColors.map((item, index) => (
                <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '5px' }}>
                    <div style={{ width: '20px', height: '20px', backgroundColor: item.color, marginRight: '10px' }}></div>
                    <div style={{ color: 'red'}}>{item.text}</div>
                </div>
            ))}
        </div>
    );
}

export default LineColorIndicator
import React, { useRef, useEffect, useState } from 'react'
import { Label } from '../styling/StyledComponents'
import mapboxgl from 'mapbox-gl';
import Cookies from 'js-cookie';
import { addBuildings } from '../MapLayers/BuildingLayer';
import { CoordsData } from '../Geolocation/CoordsProvider';
import { addSourceToCircleLayer, createCircleData, createCircleToSource } from '../MapLayers/CircleLayer';
import styled from 'styled-components';

const MapWideArea = styled.div`
  width: 100%;
  height: 15rem;
`

function CoordsMap({radius}) {
    mapboxgl.accessToken = Cookies.get('mapbox_token')
    const [coords, setCoords] = CoordsData()
    const [zoom, setZoom] = useState(12);

    const mapContainer = useRef(null);
    const map = useRef(null);
    const questionArea = useRef(null);

  useEffect(() => {
    if (map.current != null && questionArea.current != null) {
      questionArea.current.setData(createCircleData(coords.lat, coords.lng, radius, 64));
    }
  }, [radius, coords.lat, coords.lng])

  useEffect(() => {
    if (mapboxgl.accessToken == undefined || map.current) return; // initialize map only once

    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_GENERAL_MAP,
        center: [coords.lng, coords.lat],
        zoom: zoom,
        pitch: 30,
        dragPan: true,
        doubleClickZoom: true,
        scrollZoom: true,
        dragRotate: true,
      });
    } catch (err) {
      console.log(err)
      return
    }

    map.current.on('click', (e) => {
      const newLat = e.lngLat.lat
      const newLng = e.lngLat.lng

      setCoords({
        lat: newLat,
        lng: newLng
      })
      setZoom(map.current.getZoom().toFixed(2));
    })

    map.current.on('touch', (e) => {
      console.log("test")
    })

    map.current.on('load', () => {
      try {
        createCircleToSource('polygon', map.current, coords.lat, coords.lng, radius, 64);

        addBuildings(map.current)
        addSourceToCircleLayer('polygon', map.current);
        questionArea.current = map.current.getSource('polygon')
      } catch (err) {
        console.log(err)
        return
      }
    });
  }, []);

  return (
    <div>
      <div className='overlay'>
        <Label>Lng: {coords.lng.toFixed(4)} | Lat: {coords.lat.toFixed(4)}</Label>
      </div>
      <MapWideArea ref={mapContainer} />
    </div>
  );
}

export default CoordsMap

export function createLinesToSource(source, map, data) { // coordinates: [[[lng, lat], [lng, lat]], [[lng, lat]]]
    let geojson = {
        type: 'geojson',
        lineMetrics: true,
        data: createLines(data)
    };

    map.addSource(source, geojson)
}

export function createLines(data) {
    let json_data = {
        'type': 'FeatureCollection',
        'features': []
    }

    for (const key in data.google_users) {
        const user_data = data.google_users[key]
        console.log(user_data)

        let coordinates = []
        for (const instance of user_data.answers) {
            coordinates.push([instance.lng, instance.lat])
        }

        json_data.features.push(
            {
                'type': 'Feature',
                'geometry': {
                    'type': 'LineString',
                    'coordinates': coordinates
                },
                'properties': {
                    'name': user_data.name,
                    'gender': user_data.gender,
                    'age': user_data.age,
                }
            }
        )
    }

    return json_data
}

export function addSourceToLinesLayer(source, map) {
    map.addLayer({
        'id': source,
        'type': 'line',
        'source': source,
        'layout': {
            'line-join': 'round',
            'line-cap': 'round',
        },
        'paint': {
            'line-width': 3,
            'line-opacity': 0.75,
            'line-gradient': [
                'interpolate',
                ['linear'],
                ['line-progress'],
                0,
                '#FF0000',
                0.5,
                '#FFFF00',
                1,
                '#00FF80'
            ]

        }
    });
}
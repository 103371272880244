function createAvgRatingHeatmapDataToSource(source, map, data) {
    let geojson = {
        type: 'geojson',
        data: createAvgRatingHeatmapData(data),
        cluster: true,
        clusterRadius: 10, // Radius of each cluster when clustering points (defaults to 50)
        clusterProperties: {
            clusterRating: ["+", ["get", "rating"]],
            clusterMax: ["+", ["get", "max_rating"]]
        },
    };

    map.addSource(source, geojson)
}

function createAvgRatingHeatmapData(data) {
    let json_data = {
        'type': 'FeatureCollection',
        'features': []
    }

    for (var elem of data) {
        console.log(elem)
        json_data.features.push({
            "type": "Feature",
            "geometry": {
                "type": "Point",
                "coordinates": [elem.lng, elem.lat]
            },
            "properties": {
                "name": elem.name,
                "age": elem.age,
                "gender": elem.gender,
                "max_rating": elem.max_rating,
                "rating": elem.user_rating,
                "date": elem.date
            }
        })
    }

    return json_data
}

function addSourceToAvgRatingHeatmapLayer(source, map) {
    map.addLayer(
        {
          id: source,
          type: 'heatmap',
          source: source,
          paint: {
            "heatmap-weight": [
                "interpolate",
                ["linear"],
                ["/", ["number", ["get", "clusterMax"]], ["number", ["get", "clusterRating"]]],
                0, 0,
                0.5, 0.5,
                1, 1
            ],
            "heatmap-radius": 20
          }
        }, 'waterway-label'
      );
}

module.exports = {
    addSourceToAvgRatingHeatmapLayer,
    createAvgRatingHeatmapData,
    createAvgRatingHeatmapDataToSource
}
import React, { useEffect, useState } from 'react'
import { LocationData } from '../Geolocation/LocationProvider';
import axios from 'axios'
import { Label } from '../styling/StyledComponents';
import { QuestionData } from '../Geolocation/QuestionProvider';

function Questions() {
    const [location] = LocationData()
    const [questionInfo, setQuestionInfo] = QuestionData()

    const fetchQuestion = async () => {
        const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/retrieve/question`, {lat: location.lat, lng: location.lng})
        .then(res => res.data)

        if (data.success) {
            setQuestionInfo(data.result)
        } 
    }

    useEffect(() => {
        fetchQuestion()
    }, [location.lat, location.lng])

    return (
        <div className='center m-margin'>
            <Label id={questionInfo.id}>{questionInfo.question}</Label>
        </div>
    )
}

export default Questions
export const addSourceToCircleLayer = (source, map) => {
    map.addLayer({
        'id': source,
        'type': 'fill',
        'source': source,
        'layout': {},
        'paint': {
            'fill-color': 'blue',
            "fill-opacity": 0.4
        }
    });
}

export function createCircleToSource(source, map, lat, lng, radiusInKm, points=64) {
    const geojson = {
      "type": "geojson",
      "data": createCircleData(lat, lng, radiusInKm, points)
    };

    map.addSource(source, geojson);
}

// Code by Brad Dwyer at https://stackoverflow.com/questions/37599561/drawing-a-circle-with-the-radius-in-miles-meters-with-mapbox-gl-js
export const createCircleData = (lat, lng, radiusInKm, points) => {
    var ret = []
    var distanceX = radiusInKm/(111.320*Math.cos(lat*Math.PI/180));
    var distanceY = radiusInKm/110.574;

    var theta, x, y;
    for(var i=0; i<points; i++) {
        theta = (i/points)*(2*Math.PI);
        x = distanceX*Math.cos(theta);
        y = distanceY*Math.sin(theta);

        ret.push([lng+x, lat+y]);
    }
    ret.push(ret[0]);

    let data = {
        "type": "FeatureCollection",
        "features": [{
            "type": "point",
            "geometry": {
                "type": "Polygon",
                "coordinates": [ret]
            }
        }]
    }

    return data
}
import React from 'react'
import Navbar from '../Components/Navigation/Navbar'
import HomeMap from '../MapComponents/HomeMap'
import { LocationProvider } from '../Geolocation/LocationProvider'
import Questions from '../Components/Questions'
import CircleSelector from '../Components/CircleSelector'
import { QuestionProvider } from '../Geolocation/QuestionProvider'
import Footer from '../Components/Footer/Footer'

function HomePage() {
  return (
    <div className='fill-height flex-column between'>
      <Navbar></Navbar>
      <LocationProvider>
        <QuestionProvider>
          <CircleSelector></CircleSelector>
          <div className='flex-column view-area'>
            <Questions></Questions>
            <HomeMap></HomeMap>
          </div>
        </QuestionProvider>
      </LocationProvider>
      <Footer></Footer>
    </div>
  )
}

export default HomePage
import React from 'react';
import { DeleteButton, Label } from '../styling/StyledComponents';
import axios from 'axios';
import Cookies from 'js-cookie';
import { CoordsData } from '../Geolocation/CoordsProvider';

function SelectQuestion({setSelectedQuestion, questions, setQuestions}) {
    const [coords, setCoords] = CoordsData();

    const handleClick = (question) => {
        setSelectedQuestion(question);
        if (question.isGeneral == false) {
            setCoords({
                lat: question.coordinates.x,
                lng: question.coordinates.y,
            })
        }
    }
    
    const handleDelete = async (id) => {

        if (window.confirm("Confirm deletion? (Note: All associated answers will be removed.)"))
        {
            // Here you can put your logic or function that needs to be executed as per the use case.
            try {
                const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/question/delete`, {id: id}, {
                    headers: {
                        Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
                    },
                    withCredentials: true
                }).then(res => res.data);
                console.log(data)
                setQuestions(prevQuestions => prevQuestions.filter(question => question.id !== id));
                alert('Question deleted successfully');
            } catch (error) {
                console.error("Failed to delete question", error);
                alert('Failed to delete question');
            }
        }
    }

    return (
        <div>
           {questions.map((question) => {
             return (
                <div className=' flex-row between flex-gap question-row' key={question.id} onClick={() => handleClick(question)}>
                    <Label className='field'>{question.question}</Label>
                    <DeleteButton onClick={() => handleDelete(question.id)}>Delete</DeleteButton>
                </div>
            )
        })}
        </div>
    );
}

export default SelectQuestion;

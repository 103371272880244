import React from 'react'
import './Footer.css'

function Footer() {
  return (
    <div className='footer-view flex-row center'>
      <div className='footer-text'>{'\u00A9'} Urbanresearch.site</div>
    </div>
  )
}

export default Footer
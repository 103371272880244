import React, { useRef, useEffect, useState } from 'react'
import { Button, MapAreaData } from '../styling/StyledComponents'
import mapboxgl from 'mapbox-gl';
import Cookies from 'js-cookie';
import { addBuildings } from '../MapLayers/BuildingLayer';
import { LocationData } from '../Geolocation/LocationProvider';
import axios from 'axios'
import { createMarkers } from '../MapLayers/UserDataLayer';
import 'mapbox-gl/dist/mapbox-gl.css'
import StaticColorIndicator from '../Components/Color/StaticColorIndicator';
import { CSVLink, CSVDownload } from "react-csv";

function UsersDataMap() {
    mapboxgl.accessToken = Cookies.get('mapbox_token')
    const [location, setLocation] = LocationData()
    const [zoom, setZoom] = useState(12.3);
    const [data, setData] = useState([])

    const mapContainer = useRef(null);
    const map = useRef(null);

    const [diffRating, setDiffRating] = useState(0);
    const [minRating, setMinRating] = useState(0);

    const getAllData = async () => {
        const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/answers`, {},
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
            },
            withCredentials: true
        })
        .then(res => res.data)

        if (data.success) {
            console.log(data.result)
            setData(data.result)
        }
    }

  useEffect(() => {
    if (mapboxgl.accessToken == undefined || map.current) return; // initialize map only once

    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_CUSTOM_MAP,
        center: [location.lng, location.lat],
        zoom: zoom,
        pitch: 30,
        dragPan: true,
        doubleClickZoom: true,
        scrollZoom: true,
        dragRotate: true,
      });
    } catch (err) {
      console.log(err)
      return
    }

    map.current.on('move', () => {
      setLocation({
        lng: map.current.getCenter().lng.toFixed(4), 
        lat: map.current.getCenter().lat.toFixed(4)
      })
      setZoom(map.current.getZoom().toFixed(2));
    });

    map.current.on('load', () => {
      try {
        addBuildings(map.current)
      } catch (err) {
        console.log(err)
        return
      }
    });

    getAllData()
  }, []);

  useEffect(() => {
    try {
      if (map.current != null) {
        if (data.length > 0) {
          const [max_diff_rating, min_rating]  = createMarkers(data, map.current)
          setDiffRating(max_diff_rating)
          setMinRating(min_rating)
        } else {
          setDiffRating(1)
          setMinRating(1)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [data])

  return (
    <div className='fill-height'>
      <div className='flex-row right'>
        <StaticColorIndicator diff_rating={diffRating} min_rating={minRating}></StaticColorIndicator>
      </div>
      <div className='margin-top overlay'>
        <CSVLink className='text-color padding-m' data={data} separator={";"} filename={"questionnaire_data.csv"}><Button>Download</Button></CSVLink>
      </div>
      <MapAreaData className='fill-height' ref={mapContainer} />
    </div>
  );
}

export default UsersDataMap
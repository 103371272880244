import React, { useRef, useEffect, useState } from 'react'
import { MapAreaData } from '../styling/StyledComponents'
import mapboxgl from 'mapbox-gl';
import Cookies from 'js-cookie';
import { addBuildings } from '../MapLayers/BuildingLayer';
import { LocationData } from '../Geolocation/LocationProvider';
import axios from 'axios'
import { addSourceToHeatmapLayer, createHeatmapData, createHeatmapDataToSource } from '../MapLayers/HeatmapLayer';

function HeatMap() {
    mapboxgl.accessToken = Cookies.get('mapbox_token')
    const [location, setLocation] = LocationData()
    const [zoom, setZoom] = useState(12.3);
    const [data, setData] = useState([])

    const mapContainer = useRef(null);
    const map = useRef(null);
    const userData = useRef(null);

    const getAllData = async () => {
        const data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/answers`, {},
        {
            headers: {
                Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
            },
            withCredentials: true
        })
        .then(res => res.data)

        if (data.success) {
            setData(data.result)
        }
    }

  useEffect(() => {
    if (mapboxgl.accessToken == undefined || map.current) return; // initialize map only once

    try {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: process.env.REACT_APP_CUSTOM_MAP,
        center: [location.lng, location.lat],
        zoom: zoom,
        pitch: 30,
        dragPan: true,
        doubleClickZoom: true,
        scrollZoom: true,
        dragRotate: true,
      });
    } catch (err) {
      console.log(err)
      return
    }

    map.current.on('move', () => {
      setLocation({
        lng: map.current.getCenter().lng.toFixed(4), 
        lat: map.current.getCenter().lat.toFixed(4)
      })
      setZoom(map.current.getZoom().toFixed(2));
    });

    map.current.on('load', () => {
      try {
        createHeatmapDataToSource('userdata', map.current, data)
        addSourceToHeatmapLayer('userdata', map.current)
        addBuildings(map.current)
        userData.current = map.current.getSource('userdata')
      } catch (err) {
        console.log(err)
        return
      }
    });

    getAllData()
  }, []);

  useEffect(() => {

    try {
      if (userData.current != null) {
        userData.current.setData(createHeatmapData(data))
      }
    } catch (err) {
      console.log(err)
    }
  }, [data, userData.current])

  return (
      <MapAreaData ref={mapContainer} />
  );
}

export default HeatMap
import React, { useState } from 'react'
import Navbar from '../Components/Navigation/Navbar'
import { Link, useNavigate } from 'react-router-dom'
import axios from 'axios'
import { Label, InputField, Button, ErrorLabel } from "../styling/StyledComponents"
import '../styling/styles.css'
import { AuthData } from '../auth/AuthProvider';
import Cookies from 'js-cookie';
import Footer from '../Components/Footer/Footer'

function LoginPage() {
  const [user, setUser] = AuthData()
  const navigate = useNavigate();
  const [errors, setErrors] = useState({})
  const google_url = `${process.env.REACT_APP_BACKEND_URL}/auth/google`

  const [values, setValues] = useState({
    email: '',
    password: ''
  })

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/login`, values)
      .then(res => res.data)

      if (data.auth) {
      
        Cookies.set('token', data.token, { expires: 600, secure: true });
        Cookies.set('mapbox_token', data.mapbox_token, { expires: 600, secure: true });
        
        let result = data.result

        if (data.isAdmin) {
          result.isAdmin = data.isAdmin
        }

        result.auth = data.auth
        setUser(result)
        navigate('/', { replace: true })
      } else {
        setErrors({message: data.message})
      }
    } catch (err) {
      console.log(err)
      alert("Error")
    }
  }

  return (
    <div className='fill-height flex-column between'>
      <Navbar></Navbar>
      <div className='center'>
        <div className='login-form login-area'>
          <div className='flex-column center'>
            <ErrorLabel>{errors.message}</ErrorLabel>
            <form onSubmit={handleSubmit} className='flex-column login-form field-gap'>
              <Label>Email</Label>
              <InputField onChange={e => setValues({...values, email: e.target.value}) }></InputField>
              <Label>Password</Label>
              <InputField type="password" onChange={e => setValues({...values, password: e.target.value}) }></InputField>
              <Button >Login</Button>
            </form>

            <a href={google_url}>
              <Button >Google</Button>
            </a>

          </div>
          <Link to="/register">Register</Link>

        </div>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default LoginPage
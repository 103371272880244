import React from 'react'
import { AuthData } from '../../auth/AuthProvider'
import Cookies from 'js-cookie';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios'

function NavLinks() {
    const [user, setUser] = AuthData()
    const navigate = useNavigate();

    const logout = async () => {
        setUser({auth: false})
        await axios.post(`${process.env.REACT_APP_BACKEND_URL}/logout`, {})
        Cookies.remove('token')
        Cookies.remove('mapbox_token')
        navigate('/login', { replace: true })
    }

    return (
        <nav>
            <ul>
                {
                    user.auth ? (
                        <>
                            <li>
                                <Link to="/">Home</Link>
                            </li>
                            <li>
                                <Link to="/about">About</Link>
                            </li>
                            {user.isAdmin ? (
                                <>
                                    <li>
                                        <Link to="/data">Data</Link>
                                    </li>
                                    <li>
                                        <Link to="/create/question">Add Questions</Link>
                                    </li>
                                    <li>
                                        <Link to="/edit/question">Edit Questions</Link>
                                    </li>
                                </>
                            ) : (
                                <>
                                </>
                            )}
                            <li>
                                <Link onClick={logout}>Logout</Link>
                            </li>
                        </>
                    ) : (
                    <>
                        <li>
                            <Link to="/about">About</Link>
                        </li>
                        <li>
                            <Link to="/login">Login</Link>
                        </li>
                    </>
                    )
                }
            </ul>
        </nav>
    )
}

export default NavLinks
export function updateGeolocation() {
    return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                position => {
                    console.log(`Latitude: ${position.coords.latitude}, Longitude: ${position.coords.longitude}`);
                    resolve(position);
                },
                error => {
                    console.log("Unable to retrieve location:", error);
                    reject(error);
                },
                {maximumAge:4900, timeout:4000, enableHighAccuracy: false}
            );
        } else {
            console.log("Geolocation is not supported");
            reject(new Error("Geolocation is not supported"));
        }
    });
}
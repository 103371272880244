import { createContext, useContext, useState } from 'react'

// Global variable for child components
const AuthContext = createContext();
// Function to access the global variable
export const AuthData = () => {
    return useContext(AuthContext)
}

export const AuthProvider = ({children}) => {
    const [ user, setUser ] = useState({name: null, email: null, gender: null, age: null, auth: null, isAdmin: null})

    return ( // We are sending the user object and setUser function to child components
        <AuthContext.Provider value={[user, setUser]}>{children}</AuthContext.Provider>
    )
}
import React, { useEffect } from 'react'
import { AuthData } from './AuthProvider'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Cookies from 'js-cookie';

/**
 * This component is a wrapper which redirects users if they are not a admin.
 *
 * @param {Component} children The wrapped react components
 * @returns {Component} Wrapped components
 */
const AdminRoute = ({children}) => {
    const [user, setUser] = AuthData()
    const navigate = useNavigate();

    const fetchAuth = async () => {
        let data = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/hasAdmin`, {},
            {
                headers: {
                    Authorization: `Bearer ${Cookies.get('token')}` // Send the token in the Authorization header
                },
                withCredentials: true
            }
        ).then(res => res.data);

        if (data.auth) {
            let userData = data.result
            userData.auth = data.auth

            if (data.isAdmin) {
                userData.isAdmin = data.isAdmin
                setUser(userData)
            } else {
                setUser(userData)
                navigate('/', { replace: true })
            }
        } else {
            setUser(data)
            navigate('/login', { replace: true })
        }
    }

    useEffect(() => {
        if (user.isAdmin == false || user.auth == false) {
            navigate('/login', { replace: true })
        } else if (user.auth == null || user.isAdmin == null) {
            fetchAuth()
        }
    }, [navigate, user.auth])

    return children
}

export default AdminRoute
import React, { useState } from 'react'
import Navbar from '../Components/Navigation/Navbar'
import { LocationProvider } from '../Geolocation/LocationProvider'
import { Select } from '../styling/StyledComponents'
import MapSelector from '../Components/MapSelector'
import Footer from '../Components/Footer/Footer'

function AdminPage() {
  const [mapType, setMapType] = useState('all_ratings')
  return (
    <div className='box'>
      <Navbar></Navbar>
      <div className='box'>
        <LocationProvider>
          <Select className='small-width overlay' onChange={e => setMapType(e.target.value)}>
            <option value="all_ratings">All Ratings</option>
            <option value="density_heatmap">Density of ratings Heatmap</option>
            <option value="average_rating_heatmap">Average Rating Heatmap</option>
            <option value="rating_user_timeframes">Ratings with Users Time Frame</option>
          </Select>

          <MapSelector mapType={mapType} className='overlay'></MapSelector>
        </LocationProvider>
      </div>
      <Footer></Footer>
    </div>
  )
}

export default AdminPage